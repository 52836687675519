<template>
    <div >
    <div v-if="isSubmited" class="body2">
      <img class="xuanzhong" src="../../assets/img/xuanzhong.png" alt="" />

      <div class="tijiao">你好，调研已结束。</div>

      <div class="tijiao2">届时获奖名单将会在企微公布，请留意相关消息。</div>

      <div style="height:30px"></div>
    </div>

    <div v-else class="v-project-join">
      <img style="width: 100%" src="https://sho-static.shulan.com/activities/app-qr/head.jpeg" alt="">
      <!-- <div class="notice-wraper" style="background:transparent;">
        
        提示 
        <div class="notice">
          <h1>参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿</h1>
          <br />
          <p>各位同事好：</p>

          <p>临近年终，感谢各位对互联网医院的支持和一年的陪伴。</p>
          <p>
            本问卷希望了解大家使用树兰医生App的情况，以更好地为各位同事提供高效优质服务。问卷预计耗时5分钟，你的答案对我们非常重要，我们将在认真填写问卷的用户中选取前70名赠送一盒蒸汽眼罩。
          </p>
        </div>
      </div> -->

      <div>
        <!-- 自定义题目 -->
        <div class="" v-for="(item, index) in questionList" :key="item.id">
          <!-- subjectType null 类型 0-单选,1-多选,2-填空 地区3 日期4 -->
          <!-- 单选 -->
          <radio-input
            v-if="item.subjectType == 0"
            :title="item.subjectContent"
            :items="item.subjectOptions"
            @change="radioOnChange"
            :index="index"
            :isRequred="item.isRequired"
          />

          <!-- 多选 -->
          <checkbox-input
            v-else-if="item.subjectType == 1"
            :title="item.subjectContent"
            :items="item.subjectOptions"
            @change="checkboxOnChange"
            :index="index"
            :isRequred="item.isRequired"
          />

          <!-- 填空 -->
          <text-input
            v-else-if="item.subjectType == 2"
            :title="item.subjectContent"
            placeholder="请输入"
            @change="textOnChange"
            :index="index"
            :isRequred="item.isRequired"
          />

          <!-- 地区 -->
          <div v-else-if="item.subjectType == 3" @click="areaOnClick(index)">
            <location-input
              :title="item.subjectContent"
              :value="item.subjectResult"
              placeholder="请选择地区"
              :index="index"
              :isRequred="item.isRequired"
            />
          </div>

          <!-- 日期 -->
          <div v-else-if="item.subjectType == 4" @click="dateOnClick(index)">
            <date-input
              :title="item.subjectContent"
              :value="item.subjectResult"
              placeholder="请输入日期"
              :index="index"
              :isRequred="item.isRequired"
            />
          </div>
          <div class="height24"></div>
        </div>
      </div>
      <div class="tip">
   
        互联网医院衷心感谢你的帮助，
        <br />
        并保证你的信息仅用于指导产品开发，绝不外泄。
      </div>
      <div class="height80"></div>

      <div class="height80"></div>

      <div class="height80"></div>

      <div class="g-safe-area-bottom"></div>

      <!-- <submit-btn
        @click="sumbitClick"
        style="position:fixed;bottom:0;left:0;"
        title="提交"
      /> -->

      <van-popup v-model="showDatePicker" position="bottom"
        ><van-datetime-picker
          type="date"
          title="选择年月日"
          :minDate="minDate"
          :maxDate="maxDate"
          v-model="currentDate"
          :show-toolbar="true"
          @confirm="datePickerOnConfirm"
          @cancel="datePickerOnCancel"
          :formatter="formatter"
        >
        </van-datetime-picker
      ></van-popup>
      <van-popup v-model="showAreaPicker" position="bottom">
        <van-area
          title="请选择地区"
          :area-list="areaData"
          @confirm="areaPickerOnConfirm"
          @cancel="areaPickerOnCancel"
      /></van-popup>
    </div>
  </div>
</template>

<script>
import SectionView from "@/components/section-view.vue";
import SubmitBtn from "@/components/submit-btn.vue";
import TextInput from "@/components/input/text-input.vue";
import RadioInput from "@/components/input/radio-input.vue";
import DateInput from "@/components/input/date-input.vue";
import LocationInput from "../../components/input/location-input.vue";
import CheckboxInput from "../../components/input/checkbox-input.vue";
import { DatetimePicker, Popup, Area, Dialog, Toast } from "vant";
import { areaList } from "@vant/area-data";
export default {
  components: {
    SubmitBtn,
    SectionView,
    TextInput,
    RadioInput,
    DateInput,
    LocationInput,
    CheckboxInput,
    DatetimePicker,
    Popup,
    Area,
  },
  data() {
    return {
      areaData: areaList,
      // qid: '2022112810170668490110',
      qid: "2022112917184450820128",
      questionList: [],
      showDatePicker: false,
      showAreaPicker: false,
      currentDatePickerIndex: 0,
      currentAreaPickerIndex: 0,
      isBirthdayPicker: false,
      isCurrentAreaPicker: false,
      minDate: new Date(1920, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      isSubmited: false,
      shareData: {
        title: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
        desc: "快来参与调研吧。",
        link: window.location.href,
        imgUrl:
          "https://sho-static.shulan.com/activities/app-qr/app-qr-icon.png",
      },
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F7F9FC");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  mounted() {
    Dialog.alert({
              confirmButtonColor: "#12BEAE",
              title: "",
              message: '本次活动已结束',
            }).then(() => {});
            return;
    this._jssdk();
    this.getDetailRequest();
    this.scrollToTop();
    setShareDate.postMessage(
        JSON.stringify(this.shareData)
      );
  },
  methods: {
    _jssdk() {
      // if (!this.isMobile) return;
      // if (process.env.NODE_ENV !== "production") return;
      const _self = this;
      this.$axios
        .post(
          `/wx/config/params?hospitalId=34-6&url=${decodeURIComponent(
            window.location.href
          )}`,
          {},
          { basurlType: "commonurl" }
        )
        .then((res) => {
          console.log(res);
          wx.config({
            debug: res.data.results.debug,
            appId: res.data.results.appid,
            timestamp: res.data.results.timestamp * 1,
            nonceStr: res.data.results.nonceStr,
            signature: res.data.results.signature,
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
          });
          wx.ready(function() {
            console.log("wx.ready");
            wx.onMenuShareAppMessage({
              title: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
              desc: "快来参与调研吧。",
              link: window.location.href,
              imgUrl:
                "https://sho-static.shulan.com/activities/app-qr/app-qr-icon.png",
            });
            wx.onMenuShareTimeline({
              title: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
              // desc: "参与树兰互联网医院2022年度问卷调研，精美礼品等你来拿",
              link: window.location.href,
              imgUrl:
                "https://sho-static.shulan.com/activities/app-qr/app-qr-icon.png",
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isInvalidString(str) {
      return str == null || str.length <= 0;
    },
    isValidPhoneNumber(phone) {
      if (this.isInvalidString(phone)) {
        return false;
      }
      if (phone.length !== 11) {
        return false;
      }
      if (phone[0] != "1") {
        return false;
      }
      return true;
    },
    formatter(type, val) {
      if (type === "month") {
        return `${val}月`;
      }
      if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return year + "-" + month + "-" + day;
    },

    //同意,不同意
    agreeRadioOnChange(e) {
      // {"value":[2]}
      if (e.value && e.value.length > 0) {
        let id = e.value[0];
        this.agreeStatus = id;
      }
    },

    //性别
    genderRadioOnChange(e) {
      if (e.value && e.value.length > 0) {
        let id = e.value[0];
        this.sex = id;
      }
    },

    //填空
    textOnChange(e) {
      console.log(`textOnChange: ${JSON.stringify(e)}`);
      this.questionList[e.index].subjectResult = e.value;
    },

    //出生日期
    birthdayOnClick(e) {
      this.showDatePicker = true;
      this.isBirthdayPicker = true;
    },

    //所在省份和地区
    currentAreaOnClick(e) {
      this.showAreaPicker = true;
      this.isCurrentAreaPicker = true;
    },

    //地区
    areaOnClick(index) {
      this.showAreaPicker = true;
      this.currentAreaPickerIndex = index;
    },
    areaPickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`);
      this.showAreaPicker = false;
      if (this.isCurrentAreaPicker == true) {
        this.isCurrentAreaPicker = false;
        this.areaAddress = e
          .map((item) => {
            return item.name;
          })
          .join(" ");
        return;
      }

      this.questionList[this.currentAreaPickerIndex].subjectResult = e
        .map((item) => {
          return item.name;
        })
        .join(" ");
    },
    areaPickerOnCancel(e) {
      this.showAreaPicker = false;
    },

    //日期
    dateOnClick(index) {
      this.showDatePicker = true;
      this.currentDatePickerIndex = index;
    },
    datePickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`);
      this.showDatePicker = false;
      if (this.isBirthdayPicker == true) {
        this.isBirthdayPicker = false;
        this.birthday = this.timeFormat(e);
        return;
      }
      this.questionList[
        this.currentDatePickerIndex
      ].subjectResult = this.timeFormat(e);
    },
    datePickerOnCancel(e) {
      this.showDatePicker = false;
    },

    //单选
    radioOnChange(e) {
      console.log(`radioOnChange: ${JSON.stringify(e)}`);
      const subjectOptions = this.questionList[e.index].subjectOptions;

      let strList = [];
      for (let select of e.value) {
        for (let item of subjectOptions) {
          if (select == item.id) {
            strList.push(item.optionContent);
          }
        }
      }

      this.questionList[e.index].subjectOptionIds = JSON.stringify(e.value);
      this.questionList[e.index].subjectResult = strList.join(",");
    },

    //多选
    checkboxOnChange(e) {
      console.log(`checkboxOnChange: ${JSON.stringify(e)}`);

      const subjectOptions = this.questionList[e.index].subjectOptions;

      let strList = [];
      console.log(`e.value: ${JSON.stringify(e.value)}`);

      for (let select of e.value) {
        for (let item of subjectOptions) {
          if (select == item.id) {
            strList.push(item.optionContent);
          }
        }
      }

      this.questionList[e.index].subjectOptionIds = JSON.stringify(e.value);
      this.questionList[e.index].subjectResult = strList.join(",");
    },

    //地区
    locationOnChange(e) {
      console.log(`locationOnChange: ${JSON.stringify(e)}`);
      this.questionList[e.index].subjectResult = e.value;
    },

    //获取详情信息
    // getDetailRequest() {
    //   this.$axios
    //     .get(
    //       `/questionnaire/get/subjectAndOptionList/byQuestionnaireId?questionnaireId=${this.$route.query.questionnaireId}`
    //     )
    //     .then((res) => {
    //       this.questionList = res.data;
    //     });
    // },

    getDetailRequest() {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/questionnaire/get/by/id?questionnaireId=${this.qid}`)
        .then((res) => {
          Toast.clear();
          const { title, subjects } = res.data;
          // this.title = title;
          this.questionList = subjects;
          // this.subjects = subjects;
        })
        .catch(() => {
          Toast.clear();
        });
    },

    submit() {
      var subject = [];
      console.log("subjects", this.subjects);
      for (const index in this.subjects) {
        const element = this.subjects[index];
        if (element.userAnswer) {
          if (element.subjectType == 0) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: JSON.stringify([element.userAnswer]),
              subjectResult: element.subjectOptions
                .filter((e) => element.userAnswer == e.id)
                .map((e) => e.optionContent)
                .join(","),
            });
          } else if (element.subjectType == 1) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: JSON.stringify(element.userAnswer),
              subjectResult: element.subjectOptions
                .filter((e) => element.userAnswer.indexOf(e.id) != -1)
                .map((e) => e.optionContent)
                .join(","),
            });
          } else if (element.subjectType == 2) {
            subject.push({
              subjectId: element.subjectId,
              subjectOptionIds: element.userAnswer,
              subjectResult: element.userAnswer,
            });
          }

          console.log("subject", subject);
        } else {
          this.$toast.fail("请完成所有试题再提交");
          return;
        }
      }
      Toast.loading({
        message: "正在提交...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(`/get/request/token`)
        .then((res) => {
          console.log("token", res);
          this.$axios
            .post(`/audio/video/visit/questionnaire/submit`, {
              reqToken: res.data,
              visitId: this.visitId,
              questionnaireId: this.qid,
              sourceChannel: "h5",
              subject,
            })
            .then((res) => {
              this.isSubmited = true;
              Toast.clear();
            })
            .catch(() => {
              Toast.clear();
              this.$toast.fail("提交失败，请重新提交");
            });
        })
        .catch(() => {
          Toast.clear();
          this.$toast.fail("提交失败，请重新提交");
        });
    },

    //提交请求
    submitReuqest() {
      // 名称	类型	是否必须	默认值	备注	其他信息
      // name	string 非必须 姓名
      // phone	string非必须手机号
      // family_phone	string非必须家属手机号
      // sex	string非必须性别（男，女）
      // birthday	string非必须生日 yyyy-mm-dd
      // areaAddress	string非必须地址
      // clinicalRecruitmentId	string非必须临床招募id
      // sourceChannel	string非必须来源 mini,app

      // subject	object []非必须已填 题目和选项
      // item 类型: object
      // subjectId	string必须题目id
      // subjectResult	string必须题目答案  英文逗号间隔，例："苹果,香蕉,橘子"，填空返回字符串
      // subjectOptionIds	string必须所选选项，json，例：[21,25,24,26]

      let submitData = {
        questionnaireId: this.qid,
        sourceChannel: "h5", //	string非必须来源 mini,app
        questionnaireRecordDetailList: this.questionList
          .filter((item) => {
            // 保留必填项和已填项
            if (
              item.isRequired == true ||
              !this.isInvalidString(item.subjectResult)
            ) {
              return true;
            }
            return false;
          })
          .map((item) => {
            return {
              subjectId: item.subjectId,
              subjectResult: item.subjectResult,
              subjectOptionIds: item.subjectOptionIds,
            };
          }),
      };

      console.log("submitData", submitData);

      this.$axios.get(`/get/request/token`).then((res) => {
        submitData["reqToken"] = res.data;
        this.$axios
          .post(`/questionnaire/record/submit`, submitData)
          .then((res) => {
            this.isSubmited = true;
          });
      });
    },

    //提交
    sumbitClick() {
      //自定义题目的必选提示

      if (this.questionList && this.questionList.length) {
        for (const index in this.questionList) {
          const item = this.questionList[index];
          const relIndex = parseInt(index) + 1;
          if (
            item.isRequired == true &&
            (item.subjectResult == null || item.subjectResult.length <= 0)
          ) {
            this.$toast(`请完成第${relIndex}题`);
            return;
            // <!-- subjectType null 类型 0-单选,1-多选,2-填空 地区3 日期4 -->
            // if (item.subjectType == 2) {
            //   this.$toast("请输入" + item.subjectContent);
            //   return;
            // } else {
            //   this.$toast("请选择" + item.subjectContent);
            //   return;
            // }
          }
        }
      }
      //提交请求
      this.submitReuqest();
    },
    goBackToRoot() {
      this.$router.go(-1);
      // if (window.history.length > 2) {
      //   let deepth = window.history.length - 1;
      //   this.$router.go(-deepth);
      // } else {
      //   // this.$router.go(-1);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-project-join {
  background: #f7f9fc;
  .notice-wraper {
    padding: 40px 28px;
    .notice {
      white-space: normal;
      text-align: justify;
      word-break: break-all;
      font-size: 28px;
      font-weight: 400;
      color: #4f5265;
      line-height: 40px;
      a {
        color: #12beae;
      }
    }
  }
}
.tip {
  // width: 500px;
  margin: 50px auto 0;
  color: #12beae;
  text-align: center;
  font-size: 25px;
  font-family: PingFangSC-Normal, PingFang SC;
}

.body2 {
  height: 100%;
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.xuanzhong {
  margin: 150px auto 18px;
  width: 90px;
  height: 90px;
  display: block;
}
.tijiao {
  margin-top: 20px;
  font-size: 44px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #040814;
}
.tijiao2 {
  margin-top: 10px;
  font-size: 27px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #040814;
}
</style>
